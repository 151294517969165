import React, { forwardRef, ReactNode } from 'react'

import { FormLayout, FormLayoutItem } from '@te-digi/styleguide'

interface FormLayoutWrapperProps {
  children?: ReactNode
}

const FormLayoutWrapper = forwardRef<HTMLDivElement, FormLayoutWrapperProps>(
  ({ children }, ref) => (
    <FormLayout noMargin>
      <FormLayoutItem>
        <div ref={ref}>{children}</div>
      </FormLayoutItem>
    </FormLayout>
  )
)

export { FormLayoutWrapper }
