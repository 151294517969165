import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';

const StyledListItem = styled.li.withConfig({
  componentId: "sg1670__sc-xwps7y-0"
})([""]);
const StyledListItemIconContainer = styled.span.withConfig({
  componentId: "sg1670__sc-xwps7y-1"
})(["display:flex;flex:0 0 auto;margin-right:1em;transform:translateY(0.3125em);"]);
const getListStyleType = variant => {
  if (variant === 'unordered') {
    return 'disc';
  }
  if (variant === 'ordered') {
    return 'undefined';
  }
  return 'none';
};
const StyledList = styled.ul.withConfig({
  componentId: "sg1670__sc-xwps7y-2"
})(["list-style-type:", ";margin-bottom:", ";padding-left:", ";> ", "{display:", ";}> ", ":not(:last-child){margin-bottom:", ";}&{margin-top:", ";}", "{display:", ";}"], props => getListStyleType(props.$variant), props => props.$noMargin ? '0' : undefined, props => props.$variant ? undefined : '0', StyledListItem, props => props.$variant ? undefined : 'flex', StyledListItem, props => props.$gap ? tokens.space[props.$gap] : props.$variant ? undefined : tokens.space.xs, props => props.$gap ? tokens.space[props.$gap] : props.$variant ? undefined : tokens.space.xs, StyledListItemIconContainer, props => props.$variant ? 'none' : undefined);
const StyledListItemContent = styled.span.withConfig({
  componentId: "sg1670__sc-xwps7y-3"
})(["flex:1 1 auto;"]);

export { StyledList, StyledListItem, StyledListItemContent, StyledListItemIconContainer };
