import React, { ComponentProps, useState } from 'react'

import { FormText, Rating } from '@te-digi/styleguide'

const OPTIONS = [
  { value: '0', label: 'Todella huono' },
  { value: '1', label: 'Huono' },
  { value: '2', label: 'Melko huono' },
  { value: '3', label: 'Melko hyvä. Testataan pitkää pitkää pitkää tekstiä.' },
  { value: '4', label: 'Hyvä' },
  { value: '5', label: 'Todella hyvä' }
]

const RatingExample4 = () => {
  const [value, setValue] = useState<ComponentProps<typeof Rating>['value']>()

  return (
    <>
      <Rating
        isCentered={false}
        label="Lorem ipsum dolor sit amet"
        subLabel="Anna pistemäärä asteikolla 0 = Todella huono, 5 = Todella hyvä"
        onChange={e => setValue(e)}
        options={OPTIONS}
        value={value}
      />
      <FormText>
        Valittu: {value ? `${value.label} (${value.value})` : '-'}
      </FormText>
    </>
  )
}

export { RatingExample4 }
