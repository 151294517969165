import React, { ComponentProps, useState } from 'react'

import { FormText, Rating } from '@te-digi/styleguide'

const OPTIONS = [
  { value: '1', label: 'Lorem ipsum dolor sit amet' },
  { value: '2' },
  { value: '3', label: 'Sed posuere interdum sem' },
  { value: '4' },
  { value: '5', label: 'Lacinia quis facilisis sed sapien' },
  { value: '6' },
  { value: '7', label: 'Sed arcu lectus auctor vitae' },
  { value: '8' },
  { value: '9', label: 'Sed augue orci' },
  { value: '10' }
]

const RatingExample2 = () => {
  const [value, setValue] = useState<ComponentProps<typeof Rating>['value']>()

  return (
    <>
      <Rating
        error="Error"
        label="Donec placerat leo sit amet velit"
        maxText="Hyvä"
        minText="Huono"
        onChange={e => setValue(e)}
        options={OPTIONS}
        value={value}
      />
      <FormText>
        Valittu:{' '}
        {value ? `${value.label ? value.label : ''} (${value.value})` : '-'}
      </FormText>
    </>
  )
}

export { RatingExample2 }
