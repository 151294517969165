import React, { ComponentProps, useState } from 'react'

import { FormText, Rating } from '@te-digi/styleguide'

const OPTIONS = [
  { value: '0', label: 'Lorem ipsum dolor sit amet' },
  { value: '1', label: 'Consectetuer adipiscing elit' },
  { value: '2', label: 'Sed posuere interdum sem' },
  { value: '3', label: 'Quisque ligula eros ullamcorper quis' },
  { value: '4', label: 'Lacinia quis facilisis sed sapien' },
  { value: '5', label: 'Mauris varius diam vitae arcu' }
]

const RatingExample1 = () => {
  const [value, setValue] = useState<ComponentProps<typeof Rating>['value']>()

  return (
    <>
      <Rating
        label="Lorem ipsum dolor sit amet"
        maxText="Hyvä"
        minText="Huono"
        onChange={e => setValue(e)}
        options={OPTIONS}
        subLabel="Anna pistemäärä asteikolla 0 = Huono, 5 = Hyvä"
        value={value}
      />
      <FormText>
        Valittu: {value ? `${value.label} (${value.value})` : '-'}
      </FormText>
    </>
  )
}

export { RatingExample1 }
