import React from 'react'

import { List, ListItem, Paragraph, Rating } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { RatingExample1 } from '../../examples/react/RatingExample1'
import { RatingExample2 } from '../../examples/react/RatingExample2'
import { RatingExample3 } from '../../examples/react/RatingExample3'
import { RatingExample4 } from '../../examples/react/RatingExample4'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Rating"
    components={[{ component: Rating }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Playground
        example={RatingExample1}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Käyttäjän ohjeistaminen">
      <List variant="unordered">
        <ListItem>
          Kysymys, johon käyttäjä vastaa, esitetään <Code>label</Code>-propissa.
          Jos kysymys vaatii tarkempaa selitystä, annetaan tarkennus{' '}
          <Code>subLabel</Code>issa.
        </ListItem>
        <ListItem>
          Asteikon molemmat ääripäät on hyvä avata käyttäjälle{' '}
          <Code>subLabel</Code>issa, esimerkiksi muodossa &quot;Anna pistemäärä
          asteikolla 0 = Huono, 5 = Hyvä.&quot; Tämä helpottaa
          ruudunlukijakäyttäjää hahmottamaan arvosteluasteikkoa.
        </ListItem>
        <ListItem>
          <Code>subLabel</Code>in lisäksi asteikot ääripäät voi näyttää myös
          asteikon yläpuolella <Code>minText</Code> ja <Code>maxText</Code>{' '}
          -proppeja käyttäen, jos tekstit ovat lyhyitä. Pitkät tekstit
          aiheuttavat helposti epäselvyyttä, mihin asteikon vaihtoehtoon teksti
          liittyy.
        </ListItem>
      </List>
    </Section>

    <Section title="Virheviesti">
      <Playground
        example={RatingExample2}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Ohjeteksti">
      <Playground
        example={RatingExample3}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Tasaus vasemmalle">
      <Playground
        example={RatingExample4}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että käyttäjälle on annettu riittävä ohjeistus sublabelissa.
        </ListItem>
        <ListItem>
          Varmista, että jokaiselle vaihtoehdolla on kuvaava teksti, joka auttaa
          käyttäjää tekemään oikean valinnan.
        </ListItem>
        <ListItem>
          Varmista, että virheviesti auttaa käyttäjää korjaamaan virheen.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
